import React, { useEffect, useState } from "react";

import Accent from "../Accent";
import Button from "../LinkButton/button";
import StaticText from "../StaticText";

import styles from "./styles.module.less";

const Calcs = ({
    showCalculatorMortgage = false,
    showCalculatorRentVsBuy = false,
    showCalculatorHomeAffordability = false,
    showCalculatorRequiredIncome = false,
    showCalculatorRefinance = false,
    isPreview = false,
    staticTextItems = [],
}) => {
    return (
        <section className={styles.Calculators}>
            <div className={styles.inner}>
                <Calculators
                    showCalculatorMortgage={showCalculatorMortgage}
                    showCalculatorRentVsBuy={showCalculatorRentVsBuy}
                    showCalculatorHomeAffordability={
                        showCalculatorHomeAffordability
                    }
                    showCalculatorRequiredIncome={showCalculatorRequiredIncome}
                    showCalculatorRefinance={showCalculatorRefinance}
                    isPreview={isPreview}
                    staticTextItems={staticTextItems}
                />
            </div>
        </section>
    );
};

const Calculators = ({
    showCalculatorMortgage = false,
    showCalculatorRentVsBuy = false,
    showCalculatorHomeAffordability = false,
    showCalculatorRequiredIncome = false,
    showCalculatorRefinance = false,
    isPreview = false,
    staticTextItems = [],
}) => {
    const setDefaultCalculatorId = () => {
        let id = "";

        if (showCalculatorMortgage) id = "mortgage";
        else if (showCalculatorRentVsBuy) id = "rentVsBuy";
        else if (showCalculatorHomeAffordability) id = "affordability";
        else if (showCalculatorRequiredIncome) id = "requiredIncome";
        else if (showCalculatorRefinance) id = "refinance";

        return id;
    };

    const [selectedCalculator, setSelectedCalculator] = useState(
        setDefaultCalculatorId(),
    );

    const handleCalculatorButtonClick = (id = "") => {
        setSelectedCalculator(id);
    };

    useEffect(() => {
        setSelectedCalculator(setDefaultCalculatorId());
    }, [
        showCalculatorMortgage,
        showCalculatorRentVsBuy,
        showCalculatorHomeAffordability,
        showCalculatorRequiredIncome,
        showCalculatorRefinance,
    ]);

    return (
        <div className={styles.Calculators} style={{ overflowY: "hidden" }}>
            <div className={styles.buttons}>
                <Buttons
                    showCalculatorMortgage={showCalculatorMortgage}
                    showCalculatorRentVsBuy={showCalculatorRentVsBuy}
                    showCalculatorHomeAffordability={
                        showCalculatorHomeAffordability
                    }
                    showCalculatorRequiredIncome={showCalculatorRequiredIncome}
                    showCalculatorRefinance={showCalculatorRefinance}
                    handleClick={handleCalculatorButtonClick}
                    staticTextItems={staticTextItems}
                />
            </div>
            <div className={styles.calculatorContainer}>
                {(showCalculatorMortgage || isPreview) && (
                    <Calculator
                        display={selectedCalculator === "mortgage"}
                        guid={
                            process.env.GATSBY_SIGNAL_INTENT_MORTGAGE_CALC_GUID
                        }
                    />
                )}
                {(showCalculatorRentVsBuy || isPreview) && (
                    <Calculator
                        display={selectedCalculator === "rentVsBuy"}
                        guid={process.env.GATSBY_SIGNAL_INTENT_RENT_BUY_GUID}
                    />
                )}
                {(showCalculatorHomeAffordability || isPreview) && (
                    <Calculator
                        display={selectedCalculator === "affordability"}
                        guid={
                            process.env.GATSBY_SIGNAL_INTENT_AFFORDABILITY_GUID
                        }
                    />
                )}
                {(showCalculatorRequiredIncome || isPreview) && (
                    <Calculator
                        display={selectedCalculator === "requiredIncome"}
                        guid={process.env.GATSBY_SIGNAL_INTENT_INCOME_REQ_GUID}
                    />
                )}
                {(showCalculatorRefinance || isPreview) && (
                    <Calculator
                        display={selectedCalculator === "refinance"}
                        guid={
                            process.env.GATSBY_SIGNAL_INTENT_REFINANCE_CALC_GUID
                        }
                    />
                )}
            </div>
        </div>
    );
};

const Buttons = ({
    showCalculatorMortgage,
    showCalculatorRentVsBuy,
    showCalculatorHomeAffordability,
    showCalculatorRequiredIncome,
    showCalculatorRefinance,
    handleClick,
    staticTextItems = [],
}) => {
    return (
        <>
            <h1 className={styles.title}>Loan Calculators</h1>
            <Accent align="left" />
            <StaticText id="widget-calculator" items={staticTextItems} />
            {showCalculatorMortgage && (
                <CalculatorButton
                    label="Mortgage Calculator"
                    calculatorId="mortgage"
                    handleClick={handleClick}
                />
            )}
            {showCalculatorRentVsBuy && (
                <CalculatorButton
                    label="Home Rent vs Buy"
                    calculatorId="rentVsBuy"
                    handleClick={handleClick}
                />
            )}
            {showCalculatorHomeAffordability && (
                <CalculatorButton
                    label="Home Affordability"
                    calculatorId="affordability"
                    handleClick={handleClick}
                />
            )}
            {showCalculatorRequiredIncome && (
                <CalculatorButton
                    label="Mortgage Required Income"
                    calculatorId="requiredIncome"
                    handleClick={handleClick}
                />
            )}
            {showCalculatorRefinance && (
                <CalculatorButton
                    label="Home Refinance"
                    calculatorId="refinance"
                    handleClick={handleClick}
                />
            )}
        </>
    );
};

const CalculatorButton = ({ label, calculatorId, handleClick }) => {
    return (
        <Button
            label={label}
            onClick={() => {
                handleClick(calculatorId);
            }}
            customStyle={{
                width: "100%",
                marginBottom: "10px",
                cursor: "pointer",
            }}
        />
    );
};

const Calculator = ({ display = false, guid }) => {
    const styles = !display ? { display: "none" } : {};

    return (
        <div style={styles}>
            <div id="sgi" data-guid={guid}></div>
        </div>
    );
};

export default Calcs;
